import { useEffect, useState } from "react";
import Navbar from "../components/Navbar";

const destinyAPIKey = "05acfb5896e64995a37f2362f6667465";
// const XIVAPI = require("@xivapi/js");
// const xiv = new XIVAPI({
//   language: "en",
//   snake_case: true
// });


const MemberRender = (props) => {
  const member = props
  return(
    <div className="memberPlate">
      <img className="memberPlate__avatar" src={member.member.Avatar} alt="Member's Avatar"/>
      <div className="memberPlate__Text">
        <h3 className="memberPlate__title" key={member.member.ID}>{member.member.Name}</h3>
        <div className="memberPlate__rank__container">
          <h2 className="memberPlate__rank">{member.member.Rank}</h2>
          <img className="memberPlate__rankIcon" src={member.member.RankIcon} alt="Member's Rank icon"/>
        </div>
      {member.member.Rank === "Cadet" && <img src={require("../assets/images/sergeantLogo.jpg")} className="memberPlate__rankLogo" alt="Member's Rank Logo"></img>}
      </div>
    </div>
  )
}

const GuildedRender = (props) => {
  const guildedMember = props
  return(
    <div className="guildedMemberPlate">
      <h3 className="guilded__member__title" key={guildedMember.guildedMember.id}>{guildedMember.guildedMember.name}</h3>
      <img className="guilded__member__avatar" src={guildedMember.guildedMember.profilePicture} alt="Member's Guilded Profile Avatar"/>
    </div>
  )
}


export default function Roster(){

  const [memberPlate, setMemberPlate] = useState([])
  const [guidledMemberPlate, setGuildedMemberPlate] = useState([])

  useEffect(() => {
    
    const fcRender = async() => {
      try{
        let response = await fetch("https://xivapi.com/freecompany/9232660711086374482?data=FCM")
        let data = await response.json()
        const results = data.FreeCompanyMembers;
        setMemberPlate(results)
      } catch(error){
        console.log("Error fetching FFXIV API Data:", error)
      }
    }


    const destinyRender = async() => {
      try{
        let response = await fetch("https://www.bungie.net/platform/GroupV2/4839894/members",
        {
          method: "GET",
          headers: {
            "X-API-Key": destinyAPIKey
          }
        });
        let data = await response.json();
        console.log(data)
      } catch(error){
        console.log(error.error)
      }
    }

    destinyRender();

    const guildedRender = async() => {
      try{
        let response = await fetch("https://www.guilded.gg/api/teams/XjBy09aE/members")
        let data = await response.json()
        const guildedResults = data
        setGuildedMemberPlate(guildedResults.members)
      } catch(error){
        console.log("Error fetching Guilded API Data:", error)
      }
    }
    
    const planetside2Render = async() => {
      try{
        let response = await fetch("http://census.daybreakgames.com/s:AudacityID/get/ps2:v2/API/Collections/character/?name.first_lower=TheDoctorMD")
        let data = await response.json()
        console.log(data)
        const planetside2Results = data
      } catch(error){
        console.log("Error fetching Planetside2 API Data:", error)
      }
    }
    
    fcRender();
    guildedRender();
    planetside2Render();

  },[])

  return(
    <div>
      <Navbar/>
      <div className="heroBanner">
        <h1 className="pageTitle">Roster</h1>
      </div>
      <div className="memberRoster">
        {memberPlate.map((member) =>(
          <MemberRender
          key={member.ID}
          member = {member}
          />
          ))}
      </div>
      <h2 className="roster__section__title">Guilded Roster</h2>
      <div className="guildedMemberRoster">
        {guidledMemberPlate.map((guildedMember) =>(
          <GuildedRender
          key={guildedMember.id}
          guildedMember = {guildedMember}
          />
        ))}
      </div>

    </div>
  )
}
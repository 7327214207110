import { Link } from "react-router-dom"

export default function Footer() {
  return (
    <footer>
      <p className="footer__signature">2023 DNA Dev Studios</p>
      <p className="footer__signature"> | </p>
      <Link className="footer__link" to="/about">ABOUT</Link>
      <Link className="footer__link" to="/games">GAMES</Link>
      <Link className="footer__link" to="/roster">ROSTER</Link>
      <Link className="footer__link" to="/contact">CONTACT</Link>
    </footer>
  )
}

import Footer from "../components/Footer"
import GamesExploreBar from "../components/GamesExploreBar"
import HomepageCarousel, { DemoCarousel } from "../components/HomepageImageCarousel"
import Navbar from "../components/Navbar"
import NewsBar from "../components/NewsBar"


export default function Homepage(){
  return(
    <div className="homepage">
      <Navbar />
      <div className="homepage__main__section">
        <img src={require("../assets/images/audacityfulllogo.jpg")} alt="Audacity Logo" className="homepage__audacitylogo" />
        
        <GamesExploreBar />
        <NewsBar />
        {/* <HomepageCarousel /> */}
        <div>
          ReactDOM.render(<DemoCarousel />, document.querySelector('.demo-carousel'));
        </div>
        <Footer />
      </div>
    </div>
  )
}
import { Link } from "react-router-dom"

export default function GamesExploreBar() {
  return (
    <div className="gamesExploreBar">

        <h1 className="gamesExploreBar__title homepage__sectionTitle">Explore our Communities</h1>
        <p className="gamesExploreBar__subtitle homepage__sectionSubtitle">
            Join one of our divisions on their adventures across many epic games, all with the same great community!
        </p>

        <div className="gameCard__container">
            <article className="gameCard gameCard__FFXIV">
                <div className="gameCard__textContainer">
                    <h2 className="gameCard__title">Final Fantasy XIV</h2>
                    <h3 className="gameCard__subtitle">Endwalker Corps</h3>
                </div>
            </article>

            
            <article className="gameCard gameCard__ARK">
                <h2 className="gameCard__title">ARK</h2>
                <h3 className="gameCard__subtitle">Ascension Corps</h3>
            </article>

            
            <article className="gameCard gameCard__Destiny">
                <h2 className="gameCard__title">Destiny</h2>
                <h3 className="gameCard__subtitle">Lightbearer Corps</h3>
            </article>
        </div>

        <Link to="/news" className="homepage__sectionLink">SEE ALL...</Link>
    </div>
  )
}

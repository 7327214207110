import { Link } from "react-router-dom"

export default function NewsBar() {
  return (
    <div className="newsBar">
        <div className="newsBar__textContainer">
            <h1 className="newsBar__title homepage__sectionTitle">Guild Journal</h1>
            <p className="newsBar__subtitle homepage__sectionSubtitle">Our best and latest news across all games. Find out what our adventurers, tribemates, guardians have been up to!</p>
        </div>

        <div className="newsCards__container">
            <article className="newsCard">
                <img className="newsCard__image" src={require("../assets/images/audacityfulllogo.jpg")} alt="Audacity Logo"/>
                <p className="newsCard__date">Sept 9th 2023</p>
                <h2 className="newsCard__title">A New Website</h2>
                <p className="newsCard__description">A new website is upon us! Check it out as it grows with the guild!</p>
            </article>

            <article className="newsCard">
                <img className="newsCard__image" src={require("../assets/images/arkmegapithecusboss.png")} alt="Ark Megapithecus Boss"/>
                <p className="newsCard__date">Sept 4th 2023</p>
                <h2 className="newsCard__title">ARK First Boss Fight!</h2>
                <p className="newsCard__description">First Boss Fight this Saturday! Time for us to use our tames to fell the first boss: the Megapithecus!</p>
            </article>
        </div>

        <Link to="/news" className="homepage__sectionLink">ALL NEWS...</Link>
    </div>
  )
}

import Navbar from "../components/Navbar";

export default function NewMemberSignup(){
  return(
    <div>
      <Navbar />
      <div className="heroBanner">
        <h1 className="pageTitle">Welcome New Hero!</h1>
      </div>
      <div className="newMemberEmbed">
        <iframe className="notionNewMemberEmbed" src="https://notionforms.io/forms/new-member-form"></iframe>
      </div>
    </div>
  )
}
import { Component } from "react"
import { Carousel } from "react-responsive-carousel"

export class DemoCarousel extends Component{
    render(){
        return (
            <Carousel className="homepage__carousel">
              <div className="homepage__carouselImageContainer">
                <img className="homepage__carouselImage" src={require("../assets/images/audacityfulllogo.jpg")} alt="A group of players in LOTRO"/>
              </div>
              <div className="homepage__carouselImageContainer">
                <img className="homepage__carouselImage" src={require("../assets/images/lotrogroupimage.jpg")} alt="A group of players in LOTRO"/>
              </div>
            </Carousel>
          )
    }
}

// export default function HomepageCarousel(){
//     return (
//         <Carousel className="homepage__carousel">
//           <div className="homepage__carouselImageContainer">
//             <img className="homepage__carouselImage" src={require("../assets/images/audacityfulllogo.jpg")} alt="A group of players in LOTRO"/>
//           </div>
//           <div className="homepage__carouselImageContainer">
//             <img className="homepage__carouselImage" src={require("../assets/images/lotrogroupimage.jpg")} alt="A group of players in LOTRO"/>
//           </div>
//         </Carousel>
//       )

// }